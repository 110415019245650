:root {
    --primary-color: #1A374D;
    --secondary-color: #406882;
    --text-color: #B1D0E0;
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI Light', Tahoma, Geneva, Verdana, sans-serif
    /* background-color: red; */
}


nav a.active {

    color: white;

}

.my-title {
    color: var(--text-color);
    letter-spacing: .50rem;
    color: white;
    
}


/* About section styling */
.about, .portfolio, .contact, .resume {

    width: 100%;
    background-color: var(--text-color);
    padding: 50px;

}

/* .about img {

    width: 200px;

} */


.contact input {

    width: 100%;
    margin: 10px 0;
    padding: 5px;

}


.contact textarea {
    
    margin: 10px 0;
    width: 100%;
    padding: 5px;

}


/* header section styling */
header {
    display: flex;
    justify-content: space-between;
    /* background-image: linear-gradient(to bottom left, #09203f, #537895); */
    background-image: linear-gradient(to bottom right, #29323c, #485563);
    /* background-color: var(--primary-color); */
    align-items: center;
    padding: 25px;
}


header h1, a {
    /* background-color: var(--primary-color); */
    color: var(--text-color);
    /* padding: 15px; */
    position: relative;
    /* left: -25px; */
}

.about-me-text {
    font-size: 1.25rem;
}


header ul li {
    list-style: none;
    display: inline;
    margin: 25px;
    font-size: 28px;
}

header ul li a {
    text-decoration: none;
    color: var(--text-color);
}

header ul li a:hover {
    color: var(--secondary-color);
}

h1 a:hover {
    color: var(--secondary-color);
}



.site-label-primary {
    font-size: 1.8em;
    margin: 0;
    padding: 0;
}


.site-label-secondary {
    font-size: 1.2em;
    margin: 0;
    padding: 0;
}

.work-projects {
    padding: 0 20px;
    /* flex: 1 70%; */
}

.site-img {
    width: 100%;
    opacity: 75%;
}


.work-secondary-sites {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 25px 0;
}

.work-secondary-site {
    width: 47%;
    border: 10px solid var(--primary-color);
    margin-bottom: 20px;
    position: relative;
}

.work-site-label {
    position: absolute;
    bottom: 10px;
    left: 0px;
    width: 75%;
    background-color: var(--primary-color);
    color: var(--text-color);
    padding: 10px 20px;
    opacity: 85%;
}

footer {
    margin: 10px 0;
    text-align: center;
}

footer a {
    color: var(--primary-color)
}

.resume a {
    color: var(--primary-color);
}

button {
    width: 200px;
    height: 30px;
    border-radius: 15px;
    background-color: var(--primary-color);
    color: var(--text-color);
}




/* media screen sections */

@media screen and (max-width: 1024px) {

    .work-secondary-site {
        width: 100%;
    }

    .site-img {
        opacity: 100%;
    }

    .contact-me {
        display: block;
        text-align: center;
    }

    .contact-me p {
        padding: 5px;
        color: var(--primary-color);
    }
}

@media screen and (max-width: 768px) {
    
    
    section {
        margin: 50px 25px;
    }

    .site-img {
        opacity: 100%;
    }

    .contact-me {
        display: block;
        text-align: center;
    }

    .contact-me p {
        padding: 5px 0;
        color: var(--primary-color);
    }
}


@media screen and (max-width: 575px) {

    /* header styling */
    header {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    header ul li {
        font-size: 12px;
        line-height: 1.5;
        xborder: 1px solid black;
    }

    header nav {
        text-align: center;
    }

    header nav ul {
        text-align: center;
    }

    

    /* section style */
    section {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0;
    }

    
    /* contact me styling */
    .contact-me {
        display: block;
        text-align: center;
    }

    .contact-me p {
        color: var(--primary-color);
        padding: 5px 0;
    }

    .site-img:hover {
        width: 100%;
        opacity: 10%;
    }

    .portfolio {

        padding: 20px;
    }

    .work-site-label {
        position: absolute;
        bottom: 0px;
        font-size: .5rem;
        padding: 10px 10px;
    }

}